<template>
  <section class="deposit-usdc">
    <div class="h-full flex flex-col justify-between">
      <div>
        <h1 class="headline-small mb-s40" v-text="$t('transfer_usdc.title')" />

        <div class="flex justify-between pb-s16 mb-s16 border-b-1 border-gray-400">
          <span class="subheadline-x-small" v-text="$t('transfer_usdc.amount')"/>
          <span class="subheadline-small text-text-positive" v-text="formattedAmount"/>
        </div>

        <div v-if="getChainNames({ isDeposit: true }).length">
          <div class="body-text-x-large mb-s16" v-text="$t('transfer_usdc.select_chain')" />
          <CustomSelect
              :items="getChainNames({ isDeposit: true })"
              :tabindex="0"
              :selectedItem="getSelectedChainName({ isDeposit: true })"
              :isOpen="false"
              :visibleItems="4"
              :placeholder="$tc('transfer_usdc.choose_option')"
              @onSelectItem="selectChain"
          />
        </div>

        <div class="flex justify-between items-center my-s24" v-if="chainAddress">
          <div class="pr-s24">
            <p class="body-text-large" v-text="$t('transfer_usdc.dibbs_wallet', { chain: selected_deposit_chain })" />
            <p class="break-all subheadline-small" ref="chainAddress" v-text="chainAddress" />
          </div>
          <div>
            <ButtonV2
                :label="$tc('transfer_usdc.copy')"
                testId="btn-usdcCopy"
                size="small"
                version="secondary"
                @onClick="() => { copyContent(chainAddress) }"
            />
          </div>
        </div>

        <div class="flex justify-between items-center" v-if="chainAddressTag">
          <div class="pr-s24">
            <p class="body-text-large" v-text="$t('transfer_usdc.dibbs_wallet_memo', { chain: selected_deposit_chain })" />
            <p class="break-all subheadline-small" ref="chainAddressTag" v-text="chainAddressTag" />
          </div>
          <div>
            <ButtonV2
                :label="$tc('transfer_usdc.copy')"
                testId="btn-usdcCopy"
                size="small"
                version="secondary"
                @onClick="() => { copyContent(chainAddressTag) }"
            />
          </div>
        </div>
      </div>

      <div class="body-text-large mt-s16 mb-s24">{{ $t('transfer_usdc.transfer_warning') }}</div>
    </div>

    <div class="flex flex-col">
      <ButtonV2
        class="mb-s16"
        wide
        size="medium"
        version="primary"
        :label="$tc('transfer_usdc.continue')"
        testId="btn-continue"
        :inactive="!isValid || isLoading"
        :loading="isLoading"
        @onClick="continueTransfer"
      />
      <ButtonV2 
        wide
        size="medium"
        version="secondary"
        :label="$tc('transfer_usdc.close')"
        testId="btn-close"
        @onClick="cancel"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ButtonV2, CustomSelect } from '@/components/misc';
import copyToClipboard from '@/utils/clipboard';

export default {
  name: 'DepositUsdc',
  
  props: {
    amount: {
      type: [String, Number],
      required: true
    }
  },
  
  components: { 
    ButtonV2, 
    CustomSelect,
  },

  data() {
    return {
      isLoading: false,
    };
  },
  
  computed: {
    ...mapState('wallet', [
      'selected_deposit_chain',
      'circle_available_deposit_chains',
      'circle_create_chain_deposit_address',
    ]),
    ...mapGetters('wallet', [
        'getChainSpecifications',
        'getChainNames',
        'getSelectedChainName'
    ]),

    chainAddress() {
      return this.lodashGet(this.circle_create_chain_deposit_address, 'address', null);
    },

    chainAddressTag() {
      return this.lodashGet(this.circle_create_chain_deposit_address, 'address_tag', null);
    },

    formattedAmount() {
      return this.numberFormat(this.amount, 2, false, true);
    },

    isValid() {
      return this.chainAddress;
    },
  },
  
  methods: {
    ...mapActions('wallet', [
      'setDepositAmount',
      'getUsdcChains',
      'createDepositAddress',
      'setSelectedDepositChain',
      'resetUsdcDeposit',
    ]),

    selectChain(chainName) {
      const chainSpecification = this.getChainSpecifications({ isDeposit: true }).find(chain => chain.name === chainName);
      this.setSelectedDepositChain(chainSpecification.chain);
      this.isLoading = true;
    },

    continueTransfer() {
      this.showModal('DepositUsdcConfirmation');
    },

    cancel() {
      this.resetUsdcDeposit();
      this.hideModal();
    },

    async copyContent(content) {
      copyToClipboard(content)
        .then(() => this.showSuccess(this.$t('copied_to_clipboard')))
        .catch((err) => this.showError(err));
    },
  },

  mounted() {
    this.setDepositAmount(this.amount);
    this.resetUsdcDeposit();
    this.getUsdcChains({ isDeposit: true } );
  },

  watch: {
    chainAddress() {
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.deposit-usdc {
  @apply 
    flex 
    flex-col 
    justify-between
    flex-1;
  max-width: 440px;
  min-height: 600px;
}
</style>
